<template>
  <v-card 
    :dark="dark"
    :light="!dark"
    :color="background.color"
    :disabled="loading||disabled"
    :class="{ dense, disabled, expired: data.expired, activated: data.activated }"
    :style="{ 'background-image': `url(${background.pattern})` }"
    class="section-card benefits"
    @click="open(data.key)"
  >
    <v-card-title class="title text-h6 text-overline pb-0">
      {{ data.title }} {{ data.expired ? '– Expirado' : '' }}
    </v-card-title>
    <div 
      :class="{ 'pb-4': dense }"
      class="benefit-content d-flex align-center px-4"
    >
      <p 
        :class="{ 'my-4': !dense }"
        class="text text-h6 text-left font-weight-bold my-2"
        v-html="text"
      >
      </p>
      <v-img
        v-if="logo.type=='image'"
        max-width="30%"
        max-height="50%"
        eager
        :src="controller.ref"
        class="logo mx-auto rounded mb-3"
        @load="onLoad"
        @error="onLogoError"
      />
      <p
        v-else 
        :class="{ 'text-h5': !dense }"
        class="name text-h5 mx-auto"
      >
        {{ logo.ref }}
      </p>
    </div>
    <v-divider 
      v-if="!dense&&!data.expired"
      class="my-0" 
    />
    <v-card-actions 
      v-if="!dense&&!data.expired"
      class="actions px-4"
    >
      <span 
        class="text-button"
      >
        {{ data.activated ? 'Benefício Ativado' : cta }}
      </span>
      <v-spacer />
      <v-icon>
        {{ data.activated ? icons.check : icons.arrow }}
      </v-icon>
    </v-card-actions>
  </v-card>
</template>

<style scoped>

.section-card.benefits {
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}
.section-card.benefits.expired {
  opacity: .64;
}
.section-card.benefits .title {
  line-height: 1.125rem;
  opacity: .8;
}
.section-card.benefits .text {
  width: 60%;
  text-transform: uppercase;
  line-height: 1.4;
}
/* .section-card.benefits.dense .text {
  font-size: 18px !important;
} */
.section-card.benefits .name {
  text-align: center;
  max-width: 30%;
}
.section-card.benefits.expired .text {
  text-decoration: line-through;
}
.section-card.benefits .actions {
  opacity: 1;
}
.section-card.benefits.activated .actions {
  opacity: .64;
}

</style>

<script>
import { mdiCheck, mdiChevronRight, mdiHelpCircle } from "@mdi/js";

export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {
          key: null,
          title: null,
          label: null,
        }
      },
    },
    dense: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    icons: {
      help: mdiHelpCircle,
      arrow: mdiChevronRight,
      check: mdiCheck
    },
    defaults: {
      cta: [
        'Saiba mais', 
        'Ativar benefício', 
        'Ativar'
      ],
      background: {
        pattern: '/img/benefits/bg-confetti.png',
        color: 'secondary'
      }
    },
    controller: null,
    appURL: process.env.VUE_APP_ROOT
  }),
  computed: {
    dark () {
      return this.data.dark===undefined||this.data.dark===true;
    },
    background () {
      const background = _.has(this.data, 'background') ? 
        this.data.background : 
        this.defaults.background;
      return { ...this.defaults.background, ...background };
    },
    text () {
      let text = this.data.text;
      text = text.replace(/\*([^\*]+)\*/g,'<b>$1</b>').replace(/\n/, '<br>');
      return text;
    },
    logo () {
      const data = this.data;
      const type = _.has(data, 'logo') && _.indexOf(data.logo, '.')>=0 ? 
          'image' : 
          'text';
      const ref = _.has(data, 'logo') ? data.logo : _.first(_.split(data.title, ' '));
      return { type, ref };
    },
    cta () {
      const data = this.data;
      const text = _.has(data, 'cta') ? data.cta : _.sample(this.defaults.cta);
      return text;
    }
  },

  watch: {
    logo: {
      immediate: true,
      deep: true,
      handler (logo) {
        this.controller = logo;
      }
    }
  },

  methods: {
    open (key) {
      this.$emit("open", key);
    },
    onLoad (url) {
      // console.log('loaded', url);
      this.$emit('load');
    },
    onLogoError () {
      const path = this.controller.ref;
      if (_.indexOf(path, this.appURL)<0) {
        this.controller.ref = this.appURL + path;
      }
    }
  },
};
</script>
